import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | DeLovely’s Delicious Mini Donuts",
  pageDescription:
    "Learn how a popular food truck in Iowa utilizes SpotOn to sell more donuts and keep their customers coming back again and again.",
  title: "Making more dough with donuts",
  subTitle: `How Delovely’s donut food truck increased sales with SpotOn.`,
  businessName: "DeLovely’s Delicious Mini Donuts",
  industry: "Restaurants & hospitality, food trucks",
  location: "Crawfordsville, Iowa",
  products: [spotonProducts.SIDEKICK],
  description:
    "Dave and Sherri DeLovely’s food truck, DeLovely’s Delicious Mini Donuts, is a staple of the Southeastern Iowa fairgrounds. And with SpotOn, they’ve been able to bring in (and keep) more customers and simplify their operations.",
  quoteData: {
    imgName: "delovelys-delicious-mini-donuts.png",
    quote: `“It was hands-down super easy. It took one time to show Sherri how to change the menu, and she had no problem whatsoever. Changing pricing for events is instant. The technology works well.”`,
    personName: "Dave DeLovely",
    personTitle: "Owner, DeLovely’s Delicious Mini Donuts",
  },

  goal: `Delovely’s Delicious Mini Donuts needed a way to accept various digital payments without incurring hefty processing fees. And they also wanted to be able to change their menu at a moment's notice.`,
  solution: `SpotOn Sidekick enabled customers to pay by credit card, Apple Pay, or Google Pay. And with a cloud-based menu, the Delovelys could instantly update pricing and menu items from any device.`,
  results: {
    title: `The results`,
    stats: [
      { title: `20%`, description: `increase in sales` },
      {
        title: `12.5%`,
        description: `increase in customer retention`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Data insights made over easy",
    imageName: "omelet-shoppe.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-03",
    href: "/case-studies/omelet-shoppe",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.STREAMLINE_OPERATIONS,
    ],
  },
  {
    title: "Selling more crepes with smarter data",
    imageName: "crepe-corner.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-21",
    href: "/case-studies/crepe-corner",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Selling tacos, turning tables",
    imageName: "la-chiva-loka.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-14",
    href: "/case-studies/la-chiva-loka",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
